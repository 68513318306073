import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Loading } from 'element-ui';
import axios from "./utils/axios";
import lodash from 'lodash'
import GSignInButton from 'vue-google-signin-button'
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/fonts.css"
import "./assets/fonts/iconfont/iconfont.css"
// 全局组件
import SvgIcon from './components/svgIcon.vue'

Vue.config.productionTip = false;
Vue.prototype._ = lodash
Vue.prototype.$http = axios;
Vue.prototype.address = 'http://192.168.110.22'
Vue.prototype.$pathName = 'http://192.168.110.22'
Vue.prototype.$isMobile = /Mobile|Android|iphone|ipad|ipod|IOS|Macintosh|Surface Pro 7/i.test(navigator.userAgent);
Vue.use(ElementUI);
Vue.use(Loading.directive);
Vue.use(GSignInButton);
Vue.component('SvgIcon', SvgIcon)
const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context("./assets", true, /.svg$/);
requireAll(req);
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token")
  let arr = ['/', '/login', '/login/autoLogin', '/purchase/index', '/atmResult', '/purchaseResult','/main/deletion']
  if (arr.includes(to.path)) {
    next()
  } else {
    if (token) {
      next()
    } else {
      next({ path: '/' })
    }

  }
})
let pathName = window.location.hostname;
if (pathName.indexOf("mocgame") > 0) {
  Vue.prototype.$pathName = 'mocgame'
} else if (pathName.indexOf("muugame") > 0) {
  Vue.prototype.$pathName = 'muugame'
}else{
  Vue.prototype.$pathName = 'mgol'
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
