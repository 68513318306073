import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Page",
    component: () => import("../views/Page.vue"),
    children:[
      {
        path: "/",
        name: "",
        component: () => import("../views/Landing/index.vue"),
      },
    ]
  },
  
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import("../views/Login/index.vue"),
  // },
  {
    path: "/purchase/index",
    name: "Purchase",
    component: () => import("../views/Purchase/index.vue"),
  },
  {
    path: "/main/deletion",
    name: "Deletion",
    component: () => import("../views/Deletion/index.vue"),
  },
  {
    path: "/atmResult",
    name: "AtmResult",
    component: () => import("../views/AtmResult/index.vue"),
  },
  {
    path: "/purchaseResult",
    name: "PurchaseResult",
    component: () => import("../views/PurchaseResult/index.vue"),
  },
  {
    path: "/payCode",
    name: "payCode",
    component: () => import("../views/payCode/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Info/index.vue"),
    children:[
      {
        path: "/",
        name: "",
        component: () => import("../views/Login/index.vue"),
      },
      {
        path: "/Consume",
        name: "Consume",
        component: () => import("../views/consume/index.vue"),
      },
      {
        path: "/login/autoLogin",
        name: "autoLogin",
        component: () => import("../views/autoLogin/index.vue"),
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
